
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px hz-color(border-light);
  padding: hz-spacing(xs) hz-spacing(m);

  .tab {
    padding: hz-spacing(xs);
    /* stylelint-disable-next-line */
    color: #2d3c4d // replace with var when colors are published: hz-color(text-controls-default);
  }

  .tab:hover {
    border-bottom: solid 1px hz-color(text-primary);
    color: hz-color(text-primary);
    text-decoration: none;
  }
}

.tab[data-active='true'] {
  border-bottom: solid 1px hz-color(text-primary);
  color: hz-color(text-primary);
}

.tab[data-active='true']:hover {
  text-decoration: none;
}
